@import url("https://fonts.googleapis.com/css?family=Patrick+Hand");

$small-screen: 768px;
$medium-screen: 992px;
$large-screen: 1200px;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  padding: 4em;
  background: #eee;
  font: normal 22px/25px "Patrick Hand", sans-serif;
  font-family: "ReSiple-Rounded";

  text-align: center;
  line-height: 1;
}

a {
  color: #e59d13;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.emu-main-img {
  width: 90%;
  max-width: 600px;
}

.emu-text-img {
  width: 90%;
  max-width: 500px;
}

.emu-head-anim {
  width: 250px;
  height: 250px;
  position: fixed;
  &.top {
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotateX(180deg);
  }
  &.bottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &.left {
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotateZ(90deg);
  }
  &.right {
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotateZ(270deg);
  }
}

.header {
  font-size: 102px;
}

.token-addr {
  margin-top: 45px;
  font-size: 18px;
  .addr {
    margin-left: 5px;
    font-family: "Patrick Hand";
  }
}

.mob-only {
  display: none;
}

.emu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .btns-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    flex-direction: row;
  }

  /* CSS */
  .main-btn {
    background-color: #fae91e;
    background-image: url("https://i.imgur.com/UTkZN1Q.png");
    background-size: cover; /* This will ensure the image covers the entire button */
    background-position: center; /* This centers the background image */
    border-radius: 12px;
    color: #000;
    cursor: pointer;
    font-weight: bold;
    padding: 12px 15px;
    padding-top: 16px;
    text-align: center;
    transition: 200ms;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    text-shadow: 3px 3px 3px rgba(255, 255, 255, 1);
    // font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    text-decoration: none;
    margin-right: 17px;

    &:last-child {
      margin-right: 0;
    }
  }

  .main-btn:not(:disabled):hover,
  .main-btn:not(:disabled):focus {
    outline: 0;
    background: #f4e603;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
    text-shadow: none;
  }

  .main-btn:disabled {
    filter: saturate(0.2) opacity(0.5);
    -webkit-filter: saturate(0.2) opacity(0.5);
    cursor: not-allowed;
  }

  // a span {
  //   background: #fff;
  //   display: block;
  //   padding: 5px 15px;
  //   border-radius: 5px;
  //   border: 2px solid #000;
  // }
  // a:hover {
  //   box-shadow: 0 2px 0 #000, 0 2px 0px 2px #4f4e4e;
  // }
  // a:active {
  //   top: 4px;
  //   padding-bottom: 0px;
  //   box-shadow: 0 1px 0 #000;
  // }
  // a {
  //   position: relative;
  //   float: left;
  //   font: normal 22px/25px "Patrick Hand", sans-serif;
  //   margin-right: 17px;
  //   text-transform: uppercase;
  //   color: #000;
  //   text-decoration: none;
  //   padding-bottom: 3px;
  //   border-radius: 5px;
  //   box-shadow: 0 2px 0 #000;
  //   transition: padding 0.1s, box-shadow 0.1s, top 0.1s;
  //   background-image: url("data:image/gif;base64,R0lGODlhBAAEAIABAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjEgNjQuMTQwOTQ5LCAyMDEwLzEyLzA3LTEwOjU3OjAxICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1LjEgV2luZG93cyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo5NUY1OENCRDdDMDYxMUUyOTEzMEE1MEM5QzM0NDVBMyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo5NUY1OENCRTdDMDYxMUUyOTEzMEE1MEM5QzM0NDVBMyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjk1RjU4Q0JCN0MwNjExRTI5MTMwQTUwQzlDMzQ0NUEzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjk1RjU4Q0JDN0MwNjExRTI5MTMwQTUwQzlDMzQ0NUEzIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAQAAAQAsAAAAAAQABAAAAgYEEpdoeQUAOw==");
  //   &:last-child {
  //     margin-right: 0;
  //   }
  // }
}

// Define styles for medium screens (769px to 991px)
@media (max-width: $medium-screen) {
  .emu-wrapper {
    .btns-container {
      flex-direction: column;
      a {
        font-size: 32px;
        margin: auto;
        margin-bottom: 28px;
        span {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
  .mob-only {
    display: inline;
  }
  .token-addr {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    .addr {
      margin-left: auto;
    }
  }
}
