@font-face {
  font-family: "FoundersGrotesk";
  src: url("FoundersGrotesk-Light.otf") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FoundersGrotesk";
  src: url("FoundersGrotesk-Regular.otf") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FoundersGrotesk";
  src: url("FoundersGrotesk-Medium.otf") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FoundersGrotesk";
  src: url("FoundersGrotesk-Semibold.otf") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FoundersGrotesk";
  src: url("FoundersGrotesk-Bold.otf") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe Print";
  src: url("Segoe-Print-Font.ttf") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ReSiple-Rounded";
  src: url("ReSiple-Rounded.ttf.woff") format("woff"),
    url("ReSiple-Rounded.ttf.svg#ReSiple-Rounded") format("svg"),
    url("ReSiple-Rounded.ttf.eot"),
    url("ReSiple-Rounded.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
