@charset "UTF-8";
@import url(./fonts/stylesheet.css);
/* Slider */
@import url("https://fonts.googleapis.com/css?family=Patrick+Hand");
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {}/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 0; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100%; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  padding: 4em;
  background: #eee;
  font: normal 22px/25px "Patrick Hand", sans-serif;
  font-family: "ReSiple-Rounded";
  text-align: center;
  line-height: 1; }

a {
  color: #e59d13; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.emu-main-img {
  width: 90%;
  max-width: 600px; }

.emu-text-img {
  width: 90%;
  max-width: 500px; }

.emu-head-anim {
  width: 250px;
  height: 250px;
  position: fixed; }
  .emu-head-anim.top {
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotateX(180deg); }
  .emu-head-anim.bottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
  .emu-head-anim.left {
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotateZ(90deg); }
  .emu-head-anim.right {
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotateZ(270deg); }

.header {
  font-size: 102px; }

.token-addr {
  margin-top: 45px;
  font-size: 18px; }
  .token-addr .addr {
    margin-left: 5px;
    font-family: "Patrick Hand"; }

.mob-only {
  display: none; }

.emu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* CSS */ }
  .emu-wrapper .btns-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    flex-direction: row; }
  .emu-wrapper .main-btn {
    background-color: #fae91e;
    background-image: url("https://i.imgur.com/UTkZN1Q.png");
    background-size: cover;
    /* This will ensure the image covers the entire button */
    background-position: center;
    /* This centers the background image */
    border-radius: 12px;
    color: #000;
    cursor: pointer;
    font-weight: bold;
    padding: 12px 15px;
    padding-top: 16px;
    text-align: center;
    transition: 200ms;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    text-shadow: 3px 3px 3px white;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    text-decoration: none;
    margin-right: 17px; }
    .emu-wrapper .main-btn:last-child {
      margin-right: 0; }
  .emu-wrapper .main-btn:not(:disabled):hover,
  .emu-wrapper .main-btn:not(:disabled):focus {
    outline: 0;
    background: #f4e603;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
    text-shadow: none; }
  .emu-wrapper .main-btn:disabled {
    filter: saturate(0.2) opacity(0.5);
    -webkit-filter: saturate(0.2) opacity(0.5);
    cursor: not-allowed; }

@media (max-width: 992px) {
  .emu-wrapper .btns-container {
    flex-direction: column; }
    .emu-wrapper .btns-container a {
      font-size: 32px;
      margin: auto;
      margin-bottom: 28px; }
      .emu-wrapper .btns-container a span {
        padding-top: 10px;
        padding-bottom: 10px; }
  .mob-only {
    display: inline; }
  .token-addr {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word; }
    .token-addr .addr {
      margin-left: auto; } }

